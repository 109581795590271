import { gsap } from "gsap"
import useStore from "./store"
export const toggleDetail = show => {
  const timeline = gsap.timeline({ paused: true })
  if (show) {
    timeline.to(".hide-on-footer", { opacity: 0 })
    timeline.to(
      "#panelWrap",
      {
        y: -document.getElementById("#section-2").offsetTop,
        ease: "power2.inOut",
      },
      0.3
    )
    useStore.setState({ detail: true })
  } else {
    timeline.to("#panelWrap", {
      y: -document.getElementById("#section-1").offsetTop,
      ease: "power2.inOut",
    })
    timeline.to(".hide-on-footer", { opacity: 1 }, 0.3)
    useStore.setState({ detail: false })
  }
  if (!gsap.isTweening()) {
    timeline.play()
  }
}
