import React from "react"
import { toggleDetail } from "../lib/animation"
import useStore from "../lib/store"
import { animated, useSpring } from "@react-spring/web"

export default function ToggleDetailButton() {
  const currentProject = useStore(state => state.currentProject)
  const detail = useStore(state => state.detail)
  if (!currentProject) {
    return <></>
  }
  return (
    <>
      {currentProject.content?.length > 0 && (
        <div
          className="leading-8 underline p-2 flex flex-row items-center justify-center  duration-300 transition-all hover:opacity-75 cursor-pointer"
          onClick={() => toggleDetail(!detail)}
        >
          {detail ? "zurück" : "details"}
        </div>
      )}
    </>
  )
}
