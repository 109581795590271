import React, { useState, useEffect } from "react"
import Div100vh from "react-div-100vh"
import gsap from "gsap"
import { animated, useSpring } from "@react-spring/web"
import useStore from "../lib/store"
const Kontakt = () => {
  const kontakt = useStore(state => state.kontakt)
  const setKontakt = useStore(state => state.setKontakt)
  const [toggleImpressum, setToggleImpressum] = useState(null)
  useEffect(() => {
    const timeline = gsap.timeline({ paused: true })
    if (toggleImpressum === null) {
      setToggleImpressum(false)
    }
    if (!toggleImpressum) {
      timeline.add(
        gsap.fromTo("#impressum", { opacity: 1, y: 0 }, { opacity: 0, y: -20 })
      )
    }
    if (toggleImpressum) {
      timeline.add(
        gsap.fromTo("#impressum", { opacity: 0, y: -20 }, { opacity: 1, y: 0 })
      )
    }
    timeline.play()
  }, [toggleImpressum])
  const styles = useSpring({ y: kontakt ? "0%" : "100%" })
  return (
    <div
      className="fixed inset-0 z-[10000] pointer-events-none "
      onClick={() => {}}
    >
      <animated.div
        style={styles}
        className="w-full h-full overflow-auto relative pointer-events-auto bg-white"
      >
        <div className="fixed top-0 right-0">
          <button
            onClick={() => setKontakt(false)}
            className="h-20 lg:h-24 px-4 lg:px-8 underline flex items-center justify-center"
          >
            <span>schließen</span>
          </button>
        </div>
        <div
          className="hide-scrollbar"
          className="flex flex-col justify-center items-center pt-20 sm:pt-8 pb-16 overflow-scroll leading-8"
        >
          <div className="pb-8 sm:pb-16 md:pb-32">Kontakt</div>
          <div className="w-full px-4 sm:w-3/4 lg:w-1/2 xl:w-5/12 z-30 text-center">
            <div
              className="detail-content up-cursor"
              onClick={() => setKontakt(false)}
            >
              <p>
                <strong>augustin</strong>und<strong>frank</strong>/winkler
                <br></br>
                <span style={{ fontSize: 0.9 + "em" }}>ARCHITEKTEN</span> PartG
                mbB <br></br>
                <br></br>
                Schlesische Str. 29-30 <br></br>
                D-10997 Berlin
              </p>
              <p>
                <strong>t</strong> 030 612 843 57 <br></br>
                <span className="opacity-0">t </span>030 612 843 58 <br></br>{" "}
                <strong>f</strong> 030 612 843 59
              </p>
              <p>
                <strong>m</strong>{" "}
                <a href="mailto:mail@aufw.net">mail@aufw.net</a>
                <br></br>
                <strong>w</strong> www.aufw.net
              </p>
            </div>
            <div
              className="cursor-pointer underline hover:no-underline pb-8"
              onClick={() => setToggleImpressum(!toggleImpressum)}
            >
              Impressum
            </div>
            <div id="impressum" className="detail-content up-cursor">
              <p>
                <strong>augustin</strong>und<strong>frank</strong>/winkler
                <br></br>
                Architekten
              </p>
              <p>
                <strong>Georg Augustin</strong> / <strong>Ute Frank</strong> /{" "}
                <strong>Steffen Winkler</strong>
              </p>
              <p>
                Augustin und Frank / Winkler ARCHITEKTEN Partnerschaft mbB
                <br></br>
                Amtsgericht Berlin-Charlottenburg PR 1472 B <br></br>
                Schlesische Str. 29-30 <br></br>
                D-10997 Berlin
              </p>
              <p>
                <strong>t</strong> 030 612 843 57 <br></br>
                <span className="opacity-0">t </span>030 612 843 58 <br></br>{" "}
                <strong>f</strong> 030 612 843 59
              </p>
              <p>
                <strong>m</strong>{" "}
                <a href="mailto:mail@aufw.net">mail@aufw.net</a>
                <br></br>
                <strong>w</strong> www.aufw.net
              </p>
              <p>
                <strong>Berufsbezeichnung</strong>
                <br></br>
                Freischaffende Architekten
              </p>
              <p>
                <strong>USt-Identifikationsnr. nach §27a UStG:</strong>
                <br></br>
                DE 135824083
              </p>
              <p>
                <strong>Berufshaftpflichtversicherung</strong>
                <br></br>
                VHV Allgemeine Versicherung AG <br></br>
                VHV-Platz 1 <br></br>
                30177 Hannover
              </p>
              <p>
                <strong>Architektenkammer</strong>
                <br></br>
                Architektenkammer Berlin, Deutschland
              </p>
              <p>
                Dipl. Ing. Georg Augustin, Nr.3167 <br></br>
                Dipl. Ing. Ute Frank, Nr.03842 <br></br>
                M.Sc. Steffen Winkler, Nr.15667
              </p>
              <p>
                <strong>Berufsrechtliche Regelungen</strong>
                <br></br>
                <a
                  href="https://www.ak-berlin.de/"
                  target="_blank"
                  rel="noopener"
                >
                  www.ak-berlin.de
                </a>
              </p>
              <p>Berliner Architekten – und Baukammergesetz vom 06.07.2006</p>
              <p>Gesetz- und Verordnungsblatt für Berlin 62.Jg.Nr.26</p>
              <p>
                <strong>Fotos</strong>
                <br></br>
                Andrew Alberts, Berlin <br />
                Werner Huthmacher, Berlin <br></br>
                Simon Menges, Berlin <br></br>
                Sascha Weidner, Berlin <br></br>
                <strong>augustin</strong>und<strong>frank</strong>/winkler,
                Berlin
              </p>
              <p>
                <strong>Gestaltung Internetauftritt</strong> <br></br>
                Dorfmeyster <br></br>
                <a
                  href="https://www.dorfmeyster.de/"
                  target="_blank"
                  rel="noopener"
                >
                  www.dorfmeyster.de
                </a>
              </p>

              <p>
                <strong>Haftung für Inhalte</strong> <br></br>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. <br></br> <br></br>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.
              </p>
              <p>
                <strong>Haftung für Links</strong> <br></br>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. <br></br> <br></br>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </p>
              <p>
                <strong>Urheberrecht</strong> <br></br>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet.<br></br> <br></br>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
              </p>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  )
}
export default Kontakt
