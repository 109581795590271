import React, { useState, useEffect } from "react"

import Div100vh from "react-div-100vh"
import { Lazy, Pagination, Controller, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/lazy"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import ProjectDetail from "./project-detail"
import useStore from "../lib/store"
import { toggleDetail } from "../lib/animation"

const Gallery = () => {
  const [swiper, setSwiper] = useState(null)
  const currentProject = useStore(state => state.currentProject)
  const showMenu = useStore(state => state.showMenu)
  const projects = useStore(state => state.projects)
  const [detailSwiper, setDetailSwiper] = useState(null)
  useEffect(() => {
    if (currentProject) {
      if (swiper !== null) {
        swiper.slideTo(currentProject.startIndex + 1, 0)
      }
    }
  }, [currentProject])
  const setProjektName = () => {
    if (!swiper) {
      if (typeof window !== "undefined") {
        const title = document.getElementById("project-title")
        if (title !== null) {
          title.innerHTML = currentProject.title
        }
      }
    } else {
      if (typeof window !== "undefined") {
        const title = document.getElementById("project-title")
        if (title !== null) {
          const currentProjekt = projects.find(
            project =>
              swiper.realIndex + 1 <= project.endIndex &&
              swiper.realIndex + 1 > project.startIndex
          )
          title.innerHTML = currentProjekt?.title
        }
      }
    }
  }
  const swipeNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }
  const swipePrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  return (
    <div id="panelWrap" className="w-full h-screen">
      <section id="#section-1" className="section h-full">
        <Div100vh className="w-full relative h-full">
          <Swiper
            modules={[Lazy, Pagination, Controller, Keyboard]}
            onSwiper={setSwiper}
            breakpoints={{
              900: {
                allowTouchMove: false,
              },
            }}
            keyboard={true}
            onKeyPress={(swiper, keyCode) => {
              if (keyCode === 40) {
                toggleDetail(true)
              }
              if (keyCode === 38) {
                toggleDetail(false)
              }
            }}
            loop={true}
            onSlideNextTransitionEnd={() => {
              setProjektName()
            }}
            onSlidePrevTransitionEnd={() => {
              setProjektName()
            }}
            controller={{ control: detailSwiper }}
            slidesPerView={1}
            pagination={{
              type: "fraction",
              renderFraction: (currentClass, totalClass) => {
                return (
                  '<div><span id="current-slide" class="' +
                  currentClass +
                  '"></span>' +
                  " / " +
                  '<span class="' +
                  totalClass +
                  '"></span></div>' +
                  '<div id="project-title"> ' +
                  +"</div>"
                )
              },
            }}
            preloadImages={false}
            lazy={{
              loadPrevNext: true,
              preloaderClass: "projects__element__preloader",
            }}
          >
            {projects &&
              projects.map(post =>
                post.images.map(image => (
                  <SwiperSlide>
                    <Div100vh
                      className="w-full relative flex items-center justify-center z-50 cursor-pointer"
                      key={image.id}
                    >
                      <div
                        className="hide-on-menu"
                        className="hidden md:flex absolute top-0 left-0 w-full my-20 h-full z-20"
                      >
                        <div
                          onClick={swipePrev}
                          className="left-cursor flex-1 flex justify-start items-end"
                        ></div>
                        <div
                          onClick={swipeNext}
                          className="right-cursor flex-1 flex justify-end items-end"
                        ></div>
                      </div>
                      <div
                        style={{
                          zIndex: post?.content?.length > 0 ? 2000 : 0,
                        }}
                        className={` h-full py-20 lg:py-24 mx-2 sm:mx-16 md:mx-32`}
                      >
                        <div className="w-full h-full flex flex-row items-center justify-center">
                          {image.localFile && (
                            <img
                              className={`swiper-lazy ${
                                image.mediaDetails.width >
                                image.mediaDetails.height
                                  ? "w-full h-auto"
                                  : "h-full w-auto"
                              } down-cursor hidden max-h-image lg:max-h-imageLg md:block object-contain`}
                              onClick={() => {
                                if (post.content?.length > 0) {
                                  toggleDetail(true)
                                }
                              }}
                              data-src={getSrc(image.localFile)}
                              data-srcSet={getSrcSet(image.localFile)}
                              alt=""
                            />
                          )}
                          {image.localFile && (
                            <img
                              // className="swiper-lazy"
                              className="swiper-lazy block max-h-imageXs sm:max-h-image lg:max-h-imageLg md:hidden object-contain"
                              data-src={getSrc(image.localFile)}
                              data-srcset={getSrcSet(image.localFile)}
                              alt=""
                            />
                          )}
                          <div className="projects__element__preloader"></div>
                        </div>
                      </div>
                    </Div100vh>
                  </SwiperSlide>
                ))
              )}
          </Swiper>
          <div
            className={`absolute w-full bottom-0 mb-16 flex md:hidden flex-row justify-between z-40 ${
              showMenu ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="flex justify-start items-end">
              <div
                onClick={swipePrev}
                className="prev-slide"
                className="md:hidden px-4 cursor-pointer"
              >
                <img src="/left.svg"></img>
              </div>
            </div>
            <div className="flex justify-end items-end">
              <div
                onClick={swipeNext}
                className="next-slide"
                className="md:hidden px-4  cursor-pointer"
              >
                <img src="/right.svg"></img>
              </div>
            </div>
          </div>
        </Div100vh>
      </section>

      <section
        id="#section-2"
        onClick={() => toggleDetail(false)}
        className="section up-cursor"
      >
        <Swiper
          modules={[Controller]}
          onSwiper={setDetailSwiper}
          loop={true}
          allowSlideNext={false}
          allowSlidePrev={false}
          allowTouchMove={false}
          controller={{ control: swiper }}
        >
          {projects &&
            projects.map(projekt =>
              projekt.images.map(() => (
                <SwiperSlide>
                  <ProjectDetail currentProject={projekt} />
                </SwiperSlide>
              ))
            )}
        </Swiper>
      </section>
    </div>
  )
}

export default Gallery
