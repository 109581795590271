import React from "react"
import Div100vh from "react-div-100vh"

const ProjectDetail = ({ currentProject }) => {
  return (
    <Div100vh className="h-screen overflow-auto flex flex-col items-center justify-center">
      <div className="hide-scrollbar mt-20 sm:mt-0 flex flex-row justify-center items-start overflow-scroll h-75vh lg:h-50vh">
        <div
          onClick={ev => ev.stopPropagation()}
          className="w-full px-4 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-5/12 z-30  leading-8 down-cursor "
          dangerouslySetInnerHTML={{ __html: currentProject?.content }}
        />
      </div>
    </Div100vh>
  )
}
export default ProjectDetail
