import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// noting updated
import gsap from "gsap"
import ImageTrail from "./imageTrail"
import { getSrc, getSrcSet } from "gatsby-plugin-image"
import useStore from "../lib/store"
import ToggleDetailButton from "./toggleDetailButton"
import { useMeasure } from "react-use"
import { animated, useSpring } from "@react-spring/web"
import { toggleDetail } from "../lib/animation"

const imagesLoaded =
  typeof window !== `undefined` ? require("imagesloaded") : null

const Menu = () => {
  const projects = useStore(state => state.projects)
  const setCurrentProject = useStore(state => state.setCurrentProject)
  const showMenu = useStore(state => state.showMenu)
  const setShowMenu = useStore(state => state.setShowMenu)
  const data = useStaticQuery(graphql`
    {
      allWpMenu(filter: { slug: { eq: "hauptmenue" } }) {
        edges {
          node {
            id
            slug
            menuItems {
              nodes {
                childItems {
                  nodes {
                    connectedNode {
                      node {
                        id
                      }
                    }
                    label
                  }
                }
                label
                id
              }
            }
          }
        }
      }
    }
  `)
  const menuOrder = data.allWpMenu.edges[0].node.menuItems.nodes
  const styles = useSpring({ y: showMenu ? "0%" : "-100%" })
  // useEffect(() => {
  //   if (toggle === null) {
  //     gsap.set("#filter-menu", { yPercent: -100 })
  //     setToggle(false)
  //   }
  // }, [toggle])

  const changeImages = id => {
    const project = projects.find(pro => pro.id === id)
    setImage(project?.featuredImage)
  }
  const preloadImages = () => {
    return new Promise((resolve, reject) => {
      if (typeof window !== `undefined`) {
        if (window.innerWidth >= 900) {
          imagesLoaded(document.querySelectorAll(".content__img"), resolve)
        }
      }
    })
  }
  const [image, setImage] = useState(null)
  useEffect(() => {
    if (projects) {
      preloadImages().then(() => {
        // Remove the loader
        setImage(projects[0].featuredImage)
      })
    }
  }, [projects])
  useEffect(() => {
    if (image) {
      if (typeof window !== `undefined`) {
        if (window.innerWidth >= 900) {
          new ImageTrail()
        }
      }
    }
  }, [image])
  const selectProject = postId => {
    const selectedProject = projects.find(project => project.id === postId)
    toggleMenu()
    setCurrentProject(selectedProject)
    toggleDetail(false)
  }
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  return (
    <header>
      <div className="fixed w-1/2 bg-white md:bg-transparent md:w-auto px-4 lg:px-8 h-20 lg:h-24 z-50 top-0 left-0 flex flex-row justify-start items-center">
        <div className="">
          <div>
            <div
              className="cursor-pointer"
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              <div className="leading-none">
                <span className="font-md">augustin</span>und
                <span className="font-md">frank</span>/winkler{" "}
                <br className="lg:hidden" />
                <span style={{ fontSize: 0.9 + "em" }}>ARCHITEKTEN</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed w-1/2 bg-white md:bg-transparent md:w-auto px-4 lg:px-8 h-20 lg:h-24 z-40  top-0 right-0 flex flex-row items-center justify-end">
        <div className="flex flex-row space-x-4">
          {!showMenu && <ToggleDetailButton />}
          <div
            className="underline p-2 flex flex-row items-center justify-center  duration-300 transition-all hover:opacity-75 cursor-pointer"
            onClick={toggleMenu}
          >
            {showMenu ? "schließen" : "menu"}
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-screen h-screen">
        <animated.div
          style={styles}
          className="relative w-screen h-screen bg-white flex z-30"
        >
          <div className="absolute z-30 pointer-events-none w-screen h-screen">
            <div className="content">
              {image && (
                <img
                  src={getSrc(image.localFile)}
                  srcSet={getSrcSet(image.localFile)}
                  className="content__img"
                ></img>
              )}
            </div>
          </div>
          <div className="absolute z-20 w-screen h-screen flex flex-row items-center justify-center leading-8">
            <div
              id="nav-content"
              className="overflow-scroll relative  w-full"
              style={{ height: 75 + "vh" }}
            >
              <nav style={{ cursor: "ns-resize" }} className="menu">
                {menuOrder.map(
                  menu =>
                    menu.childItems.nodes.length > 0 && (
                      <div key={menu.id} className="pb-8">
                        <div>
                          <span className="font-md">{menu.label}</span>
                        </div>
                        {menu.childItems.nodes.map(child => (
                          <div
                            key={child.id}
                            onMouseEnter={() =>
                              changeImages(child.connectedNode.node.id)
                            }
                            onClick={() =>
                              selectProject(child.connectedNode.node.id)
                            }
                            className="w-full cursor-pointer"
                          >
                            <div
                              className="opacity-75 hover:opacity-100 duration-300 transition-all"
                              dangerouslySetInnerHTML={{ __html: child.label }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    )
                )}
              </nav>
            </div>
          </div>
          <div className="absolute top-0 bottom-0 right-0 flex flex-row items-center py-8 ">
            <div className="overflow-visible transform -rotate-90 flex flex-row items-center space-x-4 -translate-y-1/2 translate-x-8 md:translate-x-4">
              <img className="w-6 h-auto" src="/left.svg"></img>
              <p>
                <small>Herunterscrollen</small>
              </p>
            </div>
          </div>
        </animated.div>
      </div>
    </header>
  )
}

export default Menu
