import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import Menu from "../components/menu"
import Div100vh from "react-div-100vh"
import Gallery from "../components/gallery"
import CookieBanner from "react-cookie-banner"
import useStore from "../lib/store"
import Footer from "../components/footer"
import Kontakt from "../components/kontakt"
const IndexPage = ({ data }) => {
  const setProjects = useStore(state => state.setProjects)
  useEffect(() => {
    let slideCount = 0
    setProjects(
      data.allWpPost.edges.map(({ post }, index) => {
        let start = slideCount
        let end = slideCount + post.projekt.bilder.length
        let project = {
          id: post.id,
          index: index,
          title: post.title,
          content: post.content,
          slug: post.slug,
          startIndex: start,
          endIndex: end,
          categories: post.categories,
          featuredImage: post.projekt.titelbild,
          images: post.projekt.bilder,
        }
        slideCount = end
        return project
      })
    )
  }, [data])

  const kontakt = useStore(state => state.kontakt)
  const setKontakt = useStore(state => state.setKontakt)

  const toggleKontakt = () => {
    const timeline = gsap.timeline({ paused: true })
    if (!kontakt) {
      timeline.to(".hide-on-menu", { opacity: 0 }, 0)
    } else {
      timeline.to(".hide-on-menu", { opacity: 1 }, 0)
    }
    if (!gsap.isTweening()) {
      timeline.play()
    }
    setKontakt(!kontakt)
  }

  return (
    <div className="index-page ">
      <Layout>
        <SEO
          title=""
          description="augustinundfrank/winkler ist ein Architekturbüro der Berliner Architekten Georg Augustin, Ute Frank und Steffen Winkler."
        />
        <Div100vh className="relative w-full flex flex-row items-center md:justify-center">
          <Menu />
          <div
            id="content-wrapper"
            className="absolute inset-0 overflow-hidden"
          >
            <Gallery></Gallery>
          </div>
          <Footer toggleKontakt={toggleKontakt} />
          <Kontakt></Kontakt>
        </Div100vh>
        <div className="fixed bottom-0 left-0 right-0 z-50">
          <CookieBanner
            className="aufw-cookie"
            message="Wir benutzen keine Cookies."
            buttonMessage="Einverstanden"
            onAccept={() => {}}
            cookie="aufw-cookie-accept"
          />
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    allWpPost {
      edges {
        post: node {
          id
          slug
          content
          title

          categories {
            nodes {
              id
              name
            }
          }
          projekt {
            bilder {
              id
              sourceUrl
              mediaDetails {
                height
                width
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            titelbild {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 480
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpCategory(filter: { id: {}, name: { nin: "Uncategorized" } }) {
      edges {
        category: node {
          id
          name
        }
      }
    }
  }
`
export default IndexPage
