import create from "zustand"

const useStore = create(set => ({
  projectTitle: "Demo",
  slideIndex: 0,
  setSlideIndex: value => set(() => ({ slideIndex: value })),
  setProjectTitle: value => set(() => ({ projectTitle: value })),
  currentProject: null,
  setCurrentProject: project => set(() => ({ currentProject: project })),
  detail: false,
  setDetail: value => set(() => ({ detail: value })),
  kontakt: false,
  setKontakt: value => set(() => ({ kontakt: value })),
  showMenu: false,
  setShowMenu: value => set(() => ({ showMenu: value })),
  hasDetails: false,
  setHasDetails: value => set(() => ({ hasDetails: value })),
  projects: null,
  setProjects: values =>
    set(() => {
      return {
        projects: values,
        currentProject: values[Math.floor(Math.random() * values.length)],
      }
    }),
  categories: null,
  setCategories: cats => set(() => ({ categories: cats })),
}))

export default useStore
