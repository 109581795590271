import React from "react"

export default function Footer({ toggleKontakt }) {
  return (
    <footer className="hide-on-footer absolute m-4 lg:m-8 flex space-x-3 bottom-0 right-0  text-right z-50">
      <a
        href="https://www.instagram.com/aufw_architekten/"
        target="_blank"
        className="fixed bottom-0 hidden sm:block left-0 m-4 lg:m-8 underline md:leading-8 duration-300 transition-all hover:no-underline hover:opacity-75 cursor-pointer"
        rel="noopener noreferrer"
      >
        instagram
      </a>
      <a
        href="https://www.instagram.com/aufw_architekten/"
        target="_blank"
        className="block sm:hidden  underline md:leading-8 duration-300 transition-all hover:no-underline hover:opacity-75 cursor-pointer"
        rel="noopener noreferrer"
      >
        instagram
      </a>
      <div
        onClick={toggleKontakt}
        className="underline md:leading-8 duration-300 transition-all hover:no-underline hover:opacity-75 cursor-pointer"
      >
        kontakt{" "}
        <span className="hidden sm:inline-block underline duration-300 transition-all hover:no-underline hover:opacity-75">
          / impressum
        </span>
      </div>
    </footer>
  )
}
